import axios from 'api/api.js';

const getUser = (payload) => {
    return axios.get('users/' + payload);
};

const getAllUsers = () => {
    return axios.get('users');
};

const UserService = {
    getUser,
    getAllUsers
};

export default UserService;

