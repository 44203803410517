import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Router from 'config/Router';

import 'assets/styles/form.css';
import 'assets/styles/queries.css';
import 'assets/styles/overrides.css';
import 'assets/styles/sidebar.css';
import 'assets/styles/style.css';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min';

// import 'react-toastify/dist/ReactToastify.css';

// import reportWebVitals from './helpers/reportWebVitals';

{/* {process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_MODE
        : process.env.REACT_APP_PRO_MODE} */}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();