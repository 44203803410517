function NotFound() {
    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h1 className="text-center m-3">404</h1>
                                <div className="text-center">
                                    <h3>
                                        Страницата не е намерена!
                                    </h3>
                                </div>
                                <div className="text-center">
                                    <p>
                                        Съжаляваме. Но страницата, която търсите, не е налична.
                                    </p>
                                </div>
                                <div className="text-center">
                                    <a className="back-to-home" href="/">Към начало</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound;