import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import AuthMiddleware from 'config/AuthMiddleware';
import AuthService from 'services/auth.service';

import Layout from 'layouts/user/Layout';

import Login from 'pages/authentication/Login';
import Register from 'pages/authentication/Register';
import ForgotPassword from 'pages/authentication/ForgotPassword';
import ResetPassword from 'pages/authentication/ResetPassword';

import Dashboard from 'pages/general/Dashboard';
import Packages from 'pages/general/Packages';
import UnderConstruction from 'pages/general/UnderConstruction';

import NotFound from 'pages/errors/NotFound';
import StripeContainer from 'pages/general/Stripe/StripeContainer';

function Router() {
    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="*" element={<NotFound />} />

                <Route index element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
                <Route path="/register" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Register />} />
                {/* <Route path="/forgot-password" element={isLoggedIn ? <Navigate to="/dashboard" /> : <ForgotPassword />} /> */}
                {/* <Route path="/reset-password/:token/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <ResetPassword />} /> */}

                <Route path="/*" element={<AuthMiddleware />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    {/* <Route path="packages" element={<Packages />} /> */}
                    <Route path="under-construction" element={<UnderConstruction />} />
                    {/* <Route path="checkout" element={<StripeContainer />} /> */}
                </Route>
            </Route>
        </Routes>
    );
}

export default Router;