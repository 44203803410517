import axios from 'api/api.js';

const login = (payload) => {
    return axios.post('authentication/login', payload);
};

const register = (payload) => {
    return axios.post('authentication/register', payload);
};

const forgotPassword = (payload) => {
    return axios.post('authentication/forgot-password', payload);
};

const resetPassword = (payload) => {
    return axios.post('authentication/reset-password', payload);
};

const logout = () => {
    return localStorage.removeItem('authentication_token');
};

const isLoggedIn = () => {
    return !!localStorage.getItem('authentication_token');
};

const AuthService = {
    login,
    register,
    forgotPassword,
    resetPassword,
    logout,
    isLoggedIn
};

export default AuthService;

