import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import AuthService from 'services/auth.service';

function Sidebar(props) {
    const [isActive, setActive] = useState(false);

    const handleToggle = () => {
        setActive(!isActive);
    };

    const handleLogout = () => {
        AuthService.logout();
    };

    return (
        <>
            <div className="wrapper">
                <nav id="sidebar" className={isActive ? "active" : ""}>
                    <div className="sidebar-header">
                        <a href="/dashboard">
                            <LazyLoad height={100}>
                                <img className="img-fluid" src={require('assets/img/sidebar/logo.png')} width={135} alt="Sidebar" />
                            </LazyLoad>
                        </a>
                    </div>
                    <ul className="list-unstyled components mt-5">
                        <li>
                            <a href="/dashboard">
                                <LazyLoad height={20}>
                                    <img className="img-fluid" src={require('assets/img/sidebar/profile.svg').default} width={20} alt="Profile" />
                                    Профил
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/under-construction">
                                <LazyLoad height={20}>
                                    <img className="img-fluid" src={require('assets/img/sidebar/profile.svg').default} width={20} alt="Profile" />
                                    График
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/under-construction">
                                <LazyLoad height={20}>
                                    <img className="img-fluid" src={require('assets/img/sidebar/video.svg').default} width={20} alt="Profile" />
                                    Видео тренировки
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/under-construction">
                                <LazyLoad height={20}>
                                    <img className="img-fluid" src={require('assets/img/sidebar/resources.svg').default} width={20} alt="Profile" />
                                    Безплатни ресурси
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/packages">
                                <LazyLoad height={20}>
                                    <img className="img-fluid" src={require('assets/img/sidebar/packages.svg').default} width={20} alt="Profile" />
                                    Пакети
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/under-construction">
                                <LazyLoad height={20}>
                                    <img className="img-fluid" src={require('assets/img/sidebar/history.svg').default} width={20} alt="Profile" />
                                    История на поръчките
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/" onClick={handleLogout}>
                                <LazyLoad height={20}>
                                    <img className="img-fluid" src={require('assets/img/sidebar/exit.svg').default} width={20} alt="Profile" />
                                    Изход
                                </LazyLoad>
                            </a>
                        </li>
                    </ul>
                </nav>

                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button className="navbar-btn" id="sidebarCollapse" type="button" onClick={handleToggle}>
                                <img src={require('assets/img/icons/menu.svg').default} alt="Menu" />
                            </button>
                            <form className="d-flex">
                                <LazyLoad height={30}>
                                    <img className="img-fluid navbar-action__icon" src={require('assets/img/icons/message.svg').default} width={40} alt="Message" />
                                </LazyLoad>
                                <LazyLoad height={30}>
                                    <img className="img-fluid navbar-action__icon" src={require('assets/img/icons/notification.svg').default} width={30} alt="Menu" />
                                </LazyLoad>
                                <LazyLoad height={30}>
                                    <img className="img-fluid " src={require('assets/img/users/avatar.svg').default} width={38} alt="Avatar" />
                                </LazyLoad>
                            </form>
                        </div>
                    </nav>

                    {props.children}
                </div>
            </div >
        </>
    );
}

export default Sidebar;