import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkout from '../Checkout';

const PUBLIC_KEY = "pk_test_51KxSLcCHv53RTwBuxajwQs0ajPYlyKamRZPciiVDKPY7M9gvCvTDPAT6C3BBpysMJNnRPSe2ffOFEnwA3NfLz8Ay00GqtRORUS";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer() {
    return (
        <Elements stripe={stripeTestPromise}>
            <Checkout />
        </Elements>
    );
}